import {
  HomeFilled,
  UsergroupAddOutlined,
  DeliveredProcedureOutlined,
  UserOutlined,
  ThunderboltOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./menu.css";
import { useDataContext } from "../../context/Context";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Menulist = ({ darkTheme }) => {
  // const { logout } = useDataContext();

  const Navigate = useNavigate();
  const logout = async () => {
    if (Cookies.get("_id")) {
      Cookies.remove("access_token", { secure: true, sameSite: "None" });
      Cookies.remove("_id", { secure: true, sameSite: "None" });
      window.location.reload();
    }
    // localStorage.clear();
  };
  const data = [
    // {
    //   name: "Dashboard",
    //   icon: <HomeFilled />,
    //   path: "/dashboard",
    // },
    {
      name: "Flights",
      icon: <UserOutlined />,
      path: "/flights",
    },
    {
      name: "Bookings",
      icon: <UsergroupAddOutlined />,
      path: "/bookings",
    },
    {
      name: "User",
      icon: <DeliveredProcedureOutlined />,
      path: "/users",
    },
    {
      name: "Clients",
      icon: <DeliveredProcedureOutlined />,
      path: "/clients",
    },

    {
      name: "Payment",
      icon: <MoneyCollectOutlined />,
      path: "/Payment",
    },

    {
      name: "Airline",
      icon: <UserOutlined />,
      path: "/airlines",
    },
    {
      name: "Airport",
      icon: <UserOutlined />,
      path: "/airport",
    },

    // {
    //   name: "Reports",
    //   icon: <ThunderboltOutlined />,
    //   path: "/reports",
    // },
  ];

  return (
    <Menu theme={darkTheme} className="menu-bar">
      {data &&
        data.map((menuname, index) => (
          <Menu.Item
            key={index}
            icon={menuname.icon}
            style={{ fontWeight: 500 }}
          >
            <Link to={menuname.path}>{menuname.name}</Link>
          </Menu.Item>
        ))}

      <Menu.Item
        icon={<UsergroupAddOutlined />}
        style={{ fontWeight: 500 }}
        onClick={() => logout()}
      >
        Log Out
      </Menu.Item>
    </Menu>
  );
};

export default Menulist;
