import React from "react";
import Pageheadr from "../../components/pageheader/Pageheader";
import CreatePNR from "./Section/Addflight";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import FlightList from "./Section/FlightList";
import { API_URL } from "../../api/api";

export default function Flights() {
  const { data, loading, error } = useFetch(`${API_URL}/pnr`);

  const getReadDate = (e) => {
    const timestamp = e;
    const formatted = moment(timestamp).format("DD/MM/YYYY | HH:mm");
    // const formatted = moment(timedtamp).format("DD/MM/YYYY ");
    return formatted;
  };
  return (
    <div className="">
      <Pageheadr
        CreatePNR={<CreatePNR />}
        name="flights"
        btnName="CREATE SEAT"
      />

      <FlightList data={data} />
    </div>
  );
}
