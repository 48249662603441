import React, { useState } from "react";
import "./Style.css";
import { Button, Layout, theme } from "antd";
import Logo from "../Logo/Logo";
import Menulist from "../MenuComponents/Menu";
import ToggleThemeButton from "../MenuComponents/ToggleThemeButton";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Routesection from "../../pages/Routesection";
import { useDataContext } from "../../context/Context";

const { Header, Sider } = Layout;

export default function Sidebar() {
  const [darkTheme, setTheme] = useState(false);
  const { token } = useDataContext();
  const [collapse, setCollapse] = useState(false);

  const toggleTheme = () => {
    setTheme(!darkTheme);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <Layout>
        {token && (
          <Sider
            collapsed={collapse}
            collapsible
            trigger={null}
            theme={darkTheme ? "dark" : "light"}
          >
            <Logo darkTheme={darkTheme ? "dark" : "light"} />
            <Menulist darkTheme={darkTheme ? "dark" : "light"} />
            <ToggleThemeButton
              darkTheme={darkTheme}
              toggleTheme={toggleTheme}
            />
          </Sider>
        )}
        <Layout>
          {token && (
            <Header
              style={{
                padding: 0,
                background: colorBgContainer,
              }}
            >
              <Button
                type="text"
                className="toggle"
                onClick={() => setCollapse(!collapse)}
                icon={collapse ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              />
            </Header>
          )}
          <Routesection />
        </Layout>
      </Layout>
    </>
  );
}
