import React, { useRef, useState } from "react";
import { Table, Input, Button, Space, Modal } from "antd";
import {
  EyeFilled,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import ManageClients from "./Manage";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { API_URL } from "../../../api/api";
import useFetch from "../../../hooks/useFetch";

export default function UserList({ data }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [form, setFormdata] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { data: companyData } = useFetch(`${API_URL}/company/getAll`);

  const handleCloseModal = () => {
    setFormdata({});
    setShowModal(false);
    setIsViewMode(false);
  };

  const handleShowModal = (record, viewMode = false) => {
    setFormdata(record); // Set the entire record object here
    setIsViewMode(viewMode);
    setShowModal(true);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const menuRef = useRef(null);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "5%",
    },
    {
      title: "FIRST NAME",
      dataIndex: "FirstName",
      key: "FirstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "LastName",
      key: "LastName",
    },
    {
      title: "EMAIL",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Company",
      dataIndex: "Company",
      key: "Company",
    },

    {
      title: "TELEPHONE NO.",
      dataIndex: "Telephone",
      key: "Telephone",
    },
    {
      title: "MOBILE NO.",
      dataIndex: "Whatsapp",
      key: "Whatsapp",
    },

    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
    },
  ];
  const getReadDate = (timestamp) => {
    return moment(timestamp * 1000).format("DD/MM/YYYY | HH:mm:ss");
  };
  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    // setSelectedRow(row);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const showDeleteModal = (id) => {
    setDeleteId(id);
    setIsDeleteModalVisible(true);
    setAnchorEl(null);
  };

  // Ensure all fields are mapped from the original data
  const dataSource = data.map((client, index) => ({
    ...client,
    key: index,
    index: index + 1,
    FirstName: client.FirstName?.toUpperCase(),
    LastName: client.LastName?.toUpperCase(),
    Whatsapp: "+971" + client.Whatsapp,
    Telephone: "+971" + client.Telephone,
    Company: (
      <div style={{ textTransform: "uppercase" }}>
        {companyData.find((item) => item._id === client?.Company)?.AgencyName}
      </div>
    ),
    action: (
      <div>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl && client && client._id === client._id)}
          onClose={handleMenuClose}
          ref={menuRef}
        >
          <MenuItem onClick={() => handleShowModal(client, true)}>
            <EyeFilled style={{ marginRight: "10px" }} /> View
          </MenuItem>
          <MenuItem onClick={() => handleShowModal(client)}>
            <EditOutlined style={{ marginRight: "10px" }} /> Edit
          </MenuItem>
          <MenuItem onClick={() => showDeleteModal(client._id)}>
            <DeleteOutlined style={{ marginRight: "10px" }} /> Delete
          </MenuItem>
        </Menu>
      </div>
    ),
  }));

  return (
    <div className="">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 10 }}
      />
      <Modal
        visible={showModal}
        onCancel={handleCloseModal}
        footer={null}
        width={800}
      >
        <ManageClients name="MANAGE" data={form} isViewMode={isViewMode} />
      </Modal>
    </div>
  );
}
