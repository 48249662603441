import React from "react";
import { Routes, Route } from "react-router-dom";
import Flights from "./Flights/Flights";
import Bookings from "./Bookings/Bookings";
import Clients from "./Clients/Clients";
import DataAirlinnes from "./Airline/Airline";
import LoginUser from "./Account/Login";
import ProtectedRoute from "./ProtectedRoute";
import DataPayment from "./Payment/Payment";
import DatAirport from "./Airport/Airline";
import Home from "./Homepage/Home";
import User from "./User/User";


export default function Routesection() {
  return (
    <div>
      <Routes>
        <Route
          path="/Flights"
          element={
            <ProtectedRoute>
              <div className="container">
                <Flights />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Bookings"
          element={
            <ProtectedRoute>
              <div className="container">
                <Bookings />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <div className="container">
                <Flights />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Login"
          element={
            <div className="">
              <LoginUser />
            </div>
          }
        />
        <Route
          path="/Home"
          element={
            <div className="">
              <Home />
            </div>
          }
        />
        <Route
          path="/clients"
          element={
            <ProtectedRoute>
              <div className="container">
                <Clients />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <div className="container">
                <User />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Payment"
          element={
            <ProtectedRoute>
              <div className="container">
                <DataPayment />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/airlines"
          element={
            <ProtectedRoute>
              <div className="container">
                <DataAirlinnes />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/airport"
          element={
            <ProtectedRoute>
              <div className="container">
                <DatAirport />
              </div>
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}
