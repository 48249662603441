import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Sidebar from "./components/Sidebar/Sidebar";
import { ConfigProvider, Typography } from "antd";
import Routesection from "./pages/Routesection";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./context/Context";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Tajawal", "sans-serif"].join(","),
    },
  });

  return (
    <DataProvider>
      <ThemeProvider theme={theme}>
        <ConfigProvider
          theme={{
            token: {
              // fontFamily: "Marcellus",
              // fontFamily: "Plus Jakarta Sans",
              // fontFamily: "Varela Round",
              fontFamily: "Urbanist",
              fontFamily: "Tajawal",
              // fontFamily: "Cactus Classical Serif",
              // fontFamily: "Quicksand, sans-serif",
              fontWeight: 600,
            },
          }}
        >
          <BrowserRouter>
            <div className="App">
              <Sidebar />
            </div>
          </BrowserRouter>
        </ConfigProvider>
      </ThemeProvider>
    </DataProvider>
  );
}

export default App;
