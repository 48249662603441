import React, { useState } from "react";
import { Form, Input, Button, Switch, Select, DatePicker } from "antd";
import axios from "axios";
import { API_URL } from "../../../api/api";
import { countries } from "../../../Data/data";

const { Option } = Select;

const CreatePNR = ({ pnrData }) => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    console.log("values", values);
    setLoading(true);
    try {
      // Check if the PNR already exists in pnrData
      const pnr = pnrData.find((item) => item.PNR === values.PNR);
      if (!pnr) {
        console.log("PNR does not exist");
        setLoading(false);
        return;
      }
      const response = await axios.post(`${API_URL}/booking`, {
        ...values,
        PNR: pnr._id,
      });
      console.log("Success:", response.data);
      window.location.reload();

      // alert("Success");
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ padding: 10 }}>
      <h3>Create Booking</h3>
      <Form
        name="create_pnr"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelAlign="left"
      >
        <Form.Item
          label="Title"
          name="Title"
          rules={[{ required: true, message: "Please Select Title!" }]}
        >
          <Select>
            <Option key="Mr" value="Mr">
              Mr
            </Option>
            <Option key="Mrs" value="Mrs">
              Mrs
            </Option>
            <Option key="Mr" value="Mr">
              Mr
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="First Name"
          name="FirstName"
          rules={[{ required: true, message: "Please input First Name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="LastName"
          rules={[{ required: true, message: "Please input Last Name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nationality"
          name="Nationality"
          rules={[{ required: true, message: "Please select Nationality!" }]}
        >
          <Select>
            {countries?.map((country, b) => (
              <Option key={b} value={country.code}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Date Of Birth"
          name="DOB"
          rules={[{ required: true, message: "Please select Dtae Of Birth!" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Passport Expiry"
          name="PassportExpiry"
          rules={[
            { required: true, message: "Please select Passport Expiry!" },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="PhoneNumber"
          rules={[{ required: true, message: "Please input Phone Number!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Passport Number"
          name="PassportNumber"
          rules={[{ required: true, message: "Please input Passport Number!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="PNR"
          name="PNR"
          rules={[
            { required: true, message: "Please input PNR!" },
            {
              validator: (_, value) => {
                if (pnrData.some((item) => item.PNR === value)) {
                  return Promise.resolve();
                }
                return Promise.reject("INVALID PNR!");
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Infant" name="isInfant" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
          <div align="right">
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreatePNR;
