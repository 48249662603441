import React from "react";
import Pageheadr from "../../components/pageheader/Pageheader";
// import CreatePNR from "./Section/Addflight";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import ClientList from "./Section/Airlinelist";
// import CreateCompany from "./Section/AddAirline";
import CreateAirline from "./Section/AddAirline";
import { API_URL } from "../../api/api";

export default function DataAirlinnes() {
  const { data, loading, error } = useFetch(`${API_URL}/airline`);

  const getReadDate = (e) => {
    const timestamp = e;
    const formatted = moment(timestamp).format("DD/MM/YYYY | HH:mm");
    // const formatted = moment(timedtamp).format("DD/MM/YYYY ");
    return formatted;
  };

  return (
    <div className="">
      <Pageheadr
        CreatePNR={<CreateAirline />}
        name="Airline"
        btnName="CREATE AIRLINE"
      />
      <ClientList data={data} />
    </div>
  );
}
