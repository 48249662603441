import React, { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, Select } from "antd";
import axios from "axios";
import moment from "moment";
import { API_URL } from "../../../api/api";
import useFetch from "../../../hooks/useFetch";
import "./Style.css";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ManagePNR = ({ name, flight, isViewMode }) => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const {
    data: Airport,
    loading: loai,
    error: err,
  } = useFetch(`${API_URL}/airport`);

  useEffect(() => {
    if (flight) {
      form.setFieldsValue({
        PNR: flight.PNR,
        price: flight.price.split(" ")[0],
        Airline: flight.Airline,
        Route1: flight.route[0],
        Route2: flight.route[1],
        timeAA: [moment.unix(flight.time.AA), moment.unix(flight.time.AB)],
        timeBA: [moment.unix(flight.time.BA), moment.unix(flight.time.BB)],
        Seats: flight.Seats,
        Status: flight.status,
      });
    }
  }, [flight, form]);

  const onFinish = async (values) => {
    setLoading(true);
    console.log("values", values);
    try {
      const timeAAStart = moment(values.timeAA[0]).unix();
      const timeAAEnd = moment(values.timeAA[1]).unix();
      const timeBAStart = moment(values.timeBA[0]).unix();
      const timeBAEnd = moment(values.timeBA[1]).unix();

      const formattedValues = {
        ...values,
        time: {
          AA: timeAAStart,
          AB: timeAAEnd,
          BA: timeBAStart,
          BB: timeBAEnd,
        },
      };
      const response = await axios.post(`${API_URL}/pnr`, formattedValues);
      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ padding: 10 }}>
      <h3 align="center">{name}</h3>
      <Form
        form={form}
        name="create_pnr"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelAlign="left"
        className="PNR"
      >
        <Form.Item
          label="PNR"
          name="PNR"
          rules={[{ required: true, message: "Please input PNR!" }]}
        >
          <Input disabled={isViewMode} />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Please input price!" }]}
        >
          <Input disabled={isViewMode} />
        </Form.Item>

        <Form.Item
          label="Airline"
          name="Airline"
          rules={[{ required: true, message: "Please select Airline!" }]}
        >
          <Select placeholder="SELECT AIRLINE" disabled={isViewMode}>
            <Option value="Fly Dubai">FLY DUBAI</Option>
            <Option value="Emirates">EMIRATES</Option>
            <Option value="Salamaair">SALAM AIR</Option>
            <Option value="Qeshm">QESHM AIRLINE</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Airport 1"
          name="Route1"
          rules={[{ required: true, message: "Please input route 1!" }]}
        >
          <Select placeholder="SELECT AIRPORT 1" disabled={isViewMode}>
            {Airport?.map((a) => (
              <Option key={a._id} value={a._id}>
                {a.name} | {a.code}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Airport 2"
          name="Route2"
          rules={[{ required: true, message: "Please input route 2!" }]}
        >
          <Select placeholder="SELECT AIRPORT 2" disabled={isViewMode}>
            {Airport?.map((a) => (
              <Option key={a._id} value={a._id}>
                {a.name} | {a.code}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Time AA to AB"
          name="timeAA"
          rules={[
            { required: true, message: "Please input time range AA to AB!" },
          ]}
        >
          <RangePicker showTime disabled={isViewMode} />
        </Form.Item>

        <Form.Item
          label="Time BA to BB"
          name="timeBA"
          rules={[
            { required: true, message: "Please input time range BA to BB!" },
          ]}
        >
          <RangePicker showTime disabled={isViewMode} />
        </Form.Item>
        <Form.Item
          label="No. of Seats"
          name="Seats"
          rules={[{ required: true, message: "Please input number of seats!" }]}
        >
          <Input disabled={isViewMode} />
        </Form.Item>

        <Form.Item
          label="Status"
          name="Status"
          rules={[{ required: true, message: "Please input status!" }]}
        >
          <Select placeholder="SELECT STATUS" disabled={isViewMode}>
            <Option value="Open">Open</Option>
            <Option value="Closed">Closed</Option>
          </Select>
        </Form.Item>

        {!isViewMode && (
          <div align="center" style={{ width: "100%" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="btnblue"
              style={{ width: "50%" }}
            >
              SAVE
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ManagePNR;
