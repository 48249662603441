import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import Link from
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, Link } from "react-router-dom";
import axios from "axios"; // Import Axios for making HTTP requests
import "./login.css";
import Cookies from "js-cookie";
import { message } from "antd";

// import { API_URL } from "../../api/Api";
// import { ToastContainer, toast } from "react-toastify";

import { PNRProvider, useDataContext } from "../../context/Context";
import FlightruxLogoSvg from "../../assets/flightrixsvg.png";
import { API_URL } from "../../api/api";
// import { useClientContext } from "../../../../client/src/context/Context";

function LoginUser() {
  const navigate = useNavigate();
  const { setToken, token } = useDataContext();
  const [active, setActive] = useState(false);
  const [mailExist, setMailExist] = useState(false);
  const [otp, setOtp] = useState(false);
  const [forgetPwd, setPwd] = useState(false);
  const [dataReset, setReset] = useState(true);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    otp: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    confirmPassword: false,
    credentials: false,
    notfound: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value.trim(), // Trim whitespace from input values
    }));
  };

  axios.defaults.withCredentials = true;
  const handleSubmit = async (e) => {
    // alert("suucsjsxskkess");
    e.preventDefault();
    setLoading(true);
    setErrors({
      email: false,
      password: false,
      confirmPassword: false,
      notfound: false,
      credentials: false,
    });
    try {
      const response = await axios.post(`${API_URL}/company/checklogin`, {
        Email: formValues.email,
      });
      const { ActiveStatus } = response.data;
      setMailExist(true);
      setActive(ActiveStatus);
      // setOtp(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        const { status } = error.response;
        if (status === 500) {
          setErrors({
            email: false,
            password: false,
            confirmPassword: false,
            notfound: true,
            credentials: false,
          });
        }
        if (status === 401 || status === 404) {
          setErrors({
            email: true,
            password: false,
            confirmPassword: false,
            notfound: false,
            credentials: true,
          });
        }
      } else {
        console.error("Error:", error.message);
      }
    }
  };
  const handleOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({
      email: false,
      password: false,
      confirmPassword: false,
      notfound: false,
      credentials: false,
    });
    try {
      // setReset(true);
      const response = await axios.post(`${API_URL}/company/setPass`, {
        Email: formValues.email,
        otp: formValues.otp,
        reset: otp ? false : true,
      });

      console.log("dataxxxxxx", response.data);
      const { ActiveStatus } = response.data;
      setOtp(response.data == "success" ? true : false);
      if (response.data == "successotp") {
        // setMailExist(true);
        setActive(ActiveStatus);
        setOtp(false);
        setMailExist(true);
        setActive(false);
        setReset(false);
        setPwd(false);
      } else {
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        const { status } = error.response;
        if (status === 501) {
          setErrors({
            email: false,
            password: false,
            confirmPassword: false,
            notfound: false,
            otpfalse: true,
            credentials: false,
          });
        }
        if (status === 500) {
          setErrors({
            email: false,
            password: false,
            confirmPassword: false,
            notfound: true,
            credentials: false,
          });
        }
        if (status === 401 || status === 404) {
          setErrors({
            email: true,
            password: false,
            confirmPassword: false,
            notfound: false,
            credentials: true,
          });
        }
      } else {
        console.error("Error:", error.message);
        setLoading(false);
      }
    }
  };

  const handlePasswordSubmit = async (e) => {
    // alert("suucess");
    e.preventDefault();
    if (!active) {
      if (formValues.password !== formValues.confirmPassword) {
        setErrors({
          email: false,
          password: false,
          confirmPassword: false,
          notfound: false,
          invalidPassword: true,
          credentials: false,
        });
        // alert("errors");
        return;
      }
    }

    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/company/login`, {
        Email: formValues.email,
        password: formValues.password,
      });
      const { userId, isAdmin } = response.data;

      if (isAdmin === true) {
        message.success("Login successful");
        setTimeout(() => {
          navigate("/");
          Cookies.set("access_token", userId);
          Cookies.set("_id", userId);
          setToken(userId);
          setLoading(false);
          window.location.reload();
        }, 1500); // 3000 milliseconds = 3 seconds
      } else {
        message.error("Only Admin can log in ");
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      console.error("Error Getting:", error);
      if (error.response) {
        const { status } = error.response;
        if (status === 401 || status === 404) {
          setErrors({
            email: false,
            // password: true,
            confirmPassword: false,
            notfound: false,
            wrongData: true,
          });
        }
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <div className="logopage">
      <Container component="main" maxWidth="xs" className="mainlogin">
        <div align="center">
          <img
            src={FlightruxLogoSvg}
            alt="Flightrux Logo"
            className="logo"
            style={{ width: "40%" }}
          />
        </div>
        <br />
        <br />
        <Box className="boxlogin" align="center">
          {forgetPwd ? (
            <h5>Password Recovery</h5>
          ) : (
            <Typography component="h1" variant="h5">
              Sign In
            </Typography>
          )}

          <form
            onSubmit={
              forgetPwd
                ? handleOtp
                : mailExist
                ? handlePasswordSubmit
                : handleSubmit
            }
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formValues.email}
              onChange={handleChange}
              error={errors.email || errors.credentials || errors.notfound}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                errors.email
                  ? "Email is required"
                  : errors.credentials
                  ? "Invalid email or password"
                  : errors.notfound
                  ? "Email not registered"
                  : ""
              }
            />
            {mailExist && active && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={formValues.password}
                onChange={handleChange}
                error={
                  errors.password || errors.credentials || errors.wrongData
                }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={
                  errors.password
                    ? "Password is required"
                    : errors.credentials
                    ? "Invalid email or password"
                    : errors.wrongData
                    ? "Invalid password"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {otp && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="otp"
                label="Enter Otp"
                type={showPassword ? "text" : "password"}
                id="otp"
                autoComplete="current-password"
                value={formValues.otp}
                onChange={handleChange}
                error={errors.otpfalse}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.otpfalse ? "Invalid OTP" : ""}
              />
            )}

            {mailExist && !active && (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  value={formValues.password}
                  onChange={handleChange}
                  error={errors.password || errors.credentials}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    errors.password
                      ? "Password is required"
                      : errors.credentials
                      ? "Invalid email or password"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={formValues.confirmPassword}
                  onChange={handleChange}
                  error={
                    errors.password ||
                    errors.credentials ||
                    errors.invalidPassword
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    errors.password
                      ? "Password is required"
                      : errors.credentials
                      ? "Invalid email or password"
                      : errors.invalidPassword
                      ? "Invalid password"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}

            {forgetPwd ? (
              <p>After submitting, you will recieve verification email.</p>
            ) : (
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
            )}

            <Button
              type="submit"
              fullWidth
              className="btnlogin"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
              style={
                forgetPwd ? { background: "red" } : { background: "#00235f" }
              }
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : forgetPwd ? (
                "Reset Password"
              ) : (
                "Sign In"
              )}
            </Button>
            <Grid>
              {forgetPwd ? (
                <p
                  onClick={() => {
                    setPwd(false);
                    setActive(true);
                    setOtp(false);
                  }}
                >
                  Login ?
                </p>
              ) : (
                <p
                  onClick={() => {
                    setPwd(true);
                    setOtp(false);
                    setMailExist(false);
                  }}
                >
                  Forgot password?
                </p>
              )}
            </Grid>
            <Grid className="footer">
              <Typography component="h5">
                Don't have an account?{" "}
                <a href="/Home" target="_blank" rel="noopener noreferrer">
                  Sign Up
                </a>
              </Typography>
            </Grid>
          </form>
        </Box>
      </Container>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default LoginUser;
