import React, { useState } from "react";
import { Form, Input, Button, Select } from "antd";
import axios from "axios";
import "./Style.css";
import { API_URL } from "../../../api/api";
import { Country } from "../../../hooks/country";

const { Option } = Select;

const CreateAirport = () => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = {
        ...values,
        // Logo: "none",
        // city: "none",
      };
      const response = await axios.post(`${API_URL}/airport`, data);
      console.log("Success:", response.data);
      window.location.reload();
      // Redirect or show success message
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const options = Country.map((country, index) => ({
    value: country.name,
    label: country.name,
  }));

  return (
    <div style={{ padding: 10, display: "flex", justifyContent: "center" }}>
      <div style={{ maxWidth: 400, width: "100%" }}>
        <h3 align="center">Add Airport</h3>
        <Form
          name="create_company"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelAlign="left"
          className="PNR"
          layout="vertical"
        >
          <Form.Item
            label="AIRPORT NAME"
            name="name"
            rules={[{ required: true, message: "Please input the  name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="AIRPORT CODE"
            name="code"
            rules={[{ required: true, message: "Please input the   code!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="AIRPORT LOGO"
            name="Logo"
            rules={[
              { required: true, message: "Please input the airline logo URL!" },
            ]}
          >
            <Input style={{ textTransform: "lowercase" }} />
          </Form.Item>
          <Form.Item
            label="AIRPORT CITY"
            name="city"
            rules={[
              { required: true, message: "Please input the airline logo URL!" },
            ]}
          >
            <Input style={{ textTransform: "lowercase" }} />
          </Form.Item>

          <Form.Item
            label="AIRPORT COUNTRY"
            name="country"
            // style={{ height: 40 }}
            rules={[
              {
                required: true,
                message: "Please select Nationality!",
              },
            ]}
          >
            <Select
              showSearch
              style={{ height: 35, background: "white" }}
              placeholder="Search to Select"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={options}
            />
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                className="btnblue"
                style={{ width: "50%" }}
              >
                SAVE
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateAirport;
