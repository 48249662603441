import React from "react";
import FlightruxLogoSvg from "../../assets/flightrixsvg.png";

export default function Home() {
  return (
    <div className="logopage">
      <div className="trasn" align="center">
        <img src={FlightruxLogoSvg} />
        <br />
        <br />
        <br />
        <br />
        <h2>
          Airport to Airport (A to A) <br />
          Ticketing Portal
        </h2>

        <div className="row">
          <div className="col-lg-6 col-xs-16">
            <h4>Support</h4>
            Whatsapp +44 7938 836 160
          </div>{" "}
          <div className="col">
            <h4>Sales & Accounts</h4>
            Whatsapp +44 7938 836 160
          </div>
        </div>
      </div>
    </div>
  );
}
