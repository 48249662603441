import React from "react";
import Pageheadr from "../../components/pageheader/Pageheader";
// import CreatePNR from "./Section/Addflight";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import UserList from "./Section/UserList";
import CreateCompany from "./Section/AddClient";
import { API_URL } from "../../api/api";

export default function Users() {
  const { data: clientData, loading, error } = useFetch(`${API_URL}/user`);
  console.log("cleint", clientData);

  const getReadDate = (e) => {
    const timestamp = e;
    const formatted = moment(timestamp).format("DD/MM/YYYY | HH:mm");
    // const formatted = moment(timedtamp).format("DD/MM/YYYY ");
    return formatted;
  };

  return (
    <div className="">
      <Pageheadr
        // CreatePNR={<CreatePNR />}
        CreatePNR={<CreateCompany />}
        name="Users"
        btnName="CREATE USER"
      />

      <UserList data={clientData} />
    </div>
  );
}
