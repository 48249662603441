import React from "react";
import Pageheadr from "../../components/pageheader/Pageheader";
// import CreatePNR from "./Section/Addflight";
import useFetch from "../../hooks/useFetch";
import moment from "moment";

import { API_URL } from "../../api/api";
import CreatePayment from "./Section/AddPayment";
import PaymentLine from "./Section/PaymentList";

export default function DataPayment() {
  const { data, loading, error } = useFetch(`${API_URL}/payment`);

  const getReadDate = (e) => {
    const timestamp = e;
    const formatted = moment(timestamp).format("DD/MM/YYYY | HH:mm");
    // const formatted = moment(timedtamp).format("DD/MM/YYYY ");
    return formatted;
  };

  return (
    <div className="">
      <Pageheadr
        CreatePNR={<CreatePayment />}
        name="Payment"
        btnName="CREATE PAYMENT"
      />
      <PaymentLine data={data} />
    </div>
  );
}
