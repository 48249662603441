import React, { useState } from "react";
import { Form, Input, Button, Select } from "antd";
import axios from "axios";
import "./Style.css";
import { API_URL } from "../../../api/api";

const { Option } = Select;

const CreateAirline = () => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/airline`, values);
      console.log("Success:", response.data);
      window.location.reload();
      // Redirect or show success message
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ padding: 10, display: "flex", justifyContent: "center" }}>
      <div style={{ maxWidth: 400, width: "100%" }}>
        <h3 align="center">Create Airline</h3>
        <Form
          name="create_company"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelAlign="left"
          className="PNR"
          layout="vertical"
        >
          <Form.Item
            label="Airline"
            name="airline"
            rules={[
              { required: true, message: "Please input the airline name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Code"
            name="code"
            rules={[
              { required: true, message: "Please input the airline code!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Logo"
            name="logo"
            rules={[
              { required: true, message: "Please input the airline logo URL!" },
            ]}
          >
            <Input style={{ textTransform: "lowercase" }} />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              { required: true, message: "Please select the airline status!" },
            ]}
          >
            <Select placeholder="Select status">
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                className="btnblue"
                style={{ width: "50%" }}
              >
                SAVE
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateAirline;
