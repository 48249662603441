import React, { useState, useRef } from "react";
import { Table, Input, Button, Space, Modal } from "antd";
import {
  EyeFilled,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import ManagePNR from "./Manage";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
export default function AirlineList({ data }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [form, setFormdata] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleCloseModal = () => {
    setFormdata({});
    setShowModal(false);
    setIsViewMode(false);
  };

  const handleShowModal = (record, viewMode = false) => {
    setFormdata(record); // Set the entire record object here
    setIsViewMode(viewMode);
    setShowModal(true);
    handleMenuClose();
  };

  const handleMenuOpen = (event, record) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(record);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "5%",
    },
    {
      title: "AIRLINE LOGO",
      dataIndex: "logo",
      key: "logo",
      width: "25%",
      render: (logo) => (
        <img src={logo} alt="Airline Logo" style={{ width: 150 }} />
      ),
    },
    {
      title: "AIRLINE CODE",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "AIRLINE NAME",
      dataIndex: "airline",
      key: "airline",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "ACTION",
      key: "action",
      render: (text, record) => (
        <div className="action">
          <IconButton onClick={(e) => handleMenuOpen(e, record)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(
              anchorEl && selectedRow && selectedRow.key === record.key
            )}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleShowModal(record, true)}>
              <EyeFilled style={{ marginRight: "10px" }} /> View
            </MenuItem>
            <MenuItem onClick={() => handleShowModal(record)}>
              <EditOutlined style={{ marginRight: "10px" }} /> Edit
            </MenuItem>
            <MenuItem onClick={() => handleDelete(record.key)}>
              <DeleteOutlined style={{ marginRight: "10px" }} /> Delete
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const dataSource = data.map((Airline, index) => ({
    ...Airline,
    key: index,
    index: index + 1,
    logo: Airline.logo || "", // Ensure logo is defined
    code: Airline.code ? Airline.code.toUpperCase() : "", // Ensure code is defined
    airline: Airline.airline ? Airline.airline.toUpperCase() : "", // Ensure airline is defined
    status: Airline.status || "", // Ensure status is defined
  }));

  const handleDelete = (key) => {
    // Implement delete functionality here
    console.log("Delete record with key:", key);
    handleMenuClose();
  };

  return (
    <div className="">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 8 }}
      />
      <Modal visible={showModal} onCancel={handleCloseModal} footer={null}>
        <ManagePNR name="MANAGE" data={form} isViewMode={isViewMode} />
      </Modal>
    </div>
  );
}
