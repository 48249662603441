import React, { createContext, useContext, useEffect, useState } from "react";
import { API_URL } from "../api/api";
import axios from "axios"; // Import Axios for making HTTP requests
import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [bookPNR, setBookPNR] = useState("George");
  const [passengers, setPassenger] = useState("");
  const [token, setToken] = useState(Cookies.get("_id") || null);
  const [book, setBookingData] = useState([]);
  axios.defaults.withCredentials = true;

  // const history = useNavigate();

  const fetchData = async () => {
    try {
      const bookingResponse = await axios.get(`${API_URL}/booking`);
      setBookingData(bookingResponse.data);
      console.log("Fetching", bookingResponse.data);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.response.data);
      const { status, data } = error.response;
      if (status === 403) {
        // alert(data.msg);
        logout();
      }

      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const logout = async () => {
    if (Cookies.get("_id")) {
      Cookies.remove("access_token", { secure: true, sameSite: "None" });
      Cookies.remove("_id", { secure: true, sameSite: "None" });
      // history.push("/login");
    }
    // localStorage.clear();
  };

  return (
    <DataContext.Provider
      value={{
        bookPNR,
        setBookPNR,
        setPassenger,
        passengers,
        token,
        setToken,
        logout,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
