import React, { useState } from "react";
import { Form, Input, Button, Select, Row, Col, Space, Switch } from "antd";
import axios from "axios";
import "./Style.css";
import useFetch from "../../../hooks/useFetch";
import { API_URL } from "../../../api/api";
import { Country } from "../../../hooks/country";

const { Option } = Select;

const CreateCompany = () => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const [Active, setActive] = useState(false);
  const {
    data: Airport,
    loading: loadingAirports,
    error: errorAirports,
  } = useFetch(`${API_URL}/airport`);

  const onFinish = async (values) => {
    setLoading(true);
    // Combine the code and number for Telephone and Whatsapp
    const combinedValues = {
      ...values,
    };
    delete combinedValues.TelephoneCode;
    delete combinedValues.WhatsappCode;
    try {
      const response = await axios.post(`${API_URL}/user`, combinedValues);
      console.log("combinedValues", combinedValues);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const {
    data: Company,
    loading: load,
    error,
  } = useFetch(`${API_URL}/company`);

  const options = Company.map((country, index) => ({
    value: country._id,
    label: country.AgencyName?.toUpperCase(),
  }));

  const handleSwitchChange = (checked) => {
    setActive(!Active);
  };

  return (
    <div style={{ padding: 10 }}>
      <h3 align="center">Create User</h3>
      <Form
        name="create_company"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelAlign="right"
        className="PNR"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Salutation"
              name="Salutation"
              rules={[
                { required: true, message: "Please input the agency name!" },
              ]}
            >
              <Select
                showSearch
                style={{ height: 35, background: "white" }}
                placeholder="Select Salutation"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              >
                <Option value="Mr.">Mr.</Option>
                <Option value="Ms">Ms</Option>
                <Option value="Mrs">Mrs</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="First Name"
              name="FirstName"
              rules={[
                { required: true, message: "Please input the agency name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="LastName"
              rules={[
                { required: true, message: "Please input the agency name!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="Email"
              rules={[{ required: true, message: "Please input the email!" }]}
            >
              <Input style={{ textTransform: "" }} className="mailInput" />
            </Form.Item>
            <Form.Item
              label="Company"
              name="Company"
              // style={{ height: 40 }}
              rules={[
                {
                  required: true,
                  message: "Please select Nationality!",
                },
              ]}
            >
              <Select
                showSearch
                style={{ height: 35, background: "white" }}
                placeholder="Select Company"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={options}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Rights"
              name="Rights"
              rules={[{ required: true, message: "Please input the city!" }]}
            >
              <Select
                showSearch
                style={{ height: 35, background: "white" }}
                placeholder="Select Rights"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              >
                <Option value="FullAdmin">Full Admin</Option>
                <Option value="BookingAndReports">Booking And Reports</Option>
                <Option value="BookOnly">Booking Only</Option>
                <Option value="ReportsOnly">Reports Only</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Notification"
              name="Notification"
              rules={[{ required: true, message: "Please input the zipcode!" }]}
            >
              <Select
                showSearch
                style={{ height: 35, background: "white" }}
                placeholder="Set Notification"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              >
                <Option value="Email">Email</Option>
                <Option value="Whatsapp">Whatsapp</Option>
                <Option value="Sms">Sms</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Telephone">
              <Space.Compact style={{ height: 40 }}>
                <Form.Item name="TelephoneCode">
                  <Select
                    style={{ height: "40px", width: 80 }}
                    placeholder="Code"
                    defaultValue=""
                  >
                    <Option value="">Code</Option>
                    <Option value="971">+971</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="Telephone"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Please input the telephone number!",
                    },
                  ]}
                >
                  <Input style={{ width: "80%" }} placeholder="Telephone" />
                </Form.Item>
              </Space.Compact>
            </Form.Item>

            <Form.Item label="Whatsapp">
              <Space.Compact style={{ height: 40 }}>
                <Form.Item name="WhatsappCode">
                  <Select
                    style={{ height: "40px", width: 80 }}
                    placeholder="Code"
                    defaultValue=""
                  >
                    <Option value="">Code</Option>
                    <Option value="971">+971</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="Whatsapp"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Please input the Whatsapp number!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "80%", height: 40 }}
                    placeholder="Whatsapp"
                  />
                </Form.Item>
              </Space.Compact>
            </Form.Item>

            {/* <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input the password!" },
              ]}
            >
              <Input.Password />
            </Form.Item> */}

            <Form.Item label="Status" name="Status" valuePropName="checked">
              <Switch onChange={handleSwitchChange} />
            </Form.Item>
          </Col>
        </Row>
        <div align="center" style={{ background: "", width: "100%" }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="btnblue"
            style={{ width: "50%" }}
          >
            SAVE
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateCompany;
