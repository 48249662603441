import React from "react";
import Pageheadr from "../../components/pageheader/Pageheader";
import CreatePNR from "./Section/AddBooking";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import BookingList from "./Section/BookingList";
import { API_URL } from "../../api/api";

export default function Bookings() {
  const { data: bookingData, loading, error } = useFetch(`${API_URL}/booking`);

  const {
    data: pnrData,
    loading: pnrLoading,
    error: pnrError,
  } = useFetch(`${API_URL}/pnr`);

  return (
    <div className="">
      <Pageheadr
        CreatePNR={<CreatePNR pnrData={pnrData} />}
        name="bookings"
        btnName="add booking"
      />
      <BookingList data={bookingData} pnrData={pnrData} />
    </div>
  );
}
 
