import React, { useRef, useState } from "react";
import { Table, Input, Button, Space, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import ManagePNR from "../../Flights/Section/Manage";
import { API_URL } from "../../../api/api";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import { EyeFilled, EditOutlined, DeleteOutlined } from "@ant-design/icons";

export default function BookingList({ data, pnrData }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { data: Airport, loading, error } = useFetch(`${API_URL}/airport`);
  const { data: allPpl } = useFetch(`${API_URL}/user`);
  const { data: companyData } = useFetch(`${API_URL}/company/getAll`);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);

  const menuRef = useRef(null);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFlight(null);
    setIsViewMode(false);
  };

  const handleShowModal = (flight, viewMode = false) => {
    setSelectedFlight(flight);
    setIsViewMode(viewMode);
    setShowModal(true);
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const showDeleteModal = (id) => {
    setDeleteId(id);
    setIsDeleteModalVisible(true);
    setAnchorEl(null);
  };

  const formatTime = (timestamp) => {
    // return moment(timestamp * 1000).format("DD/MM/YYYY | HH:mm");
    return moment(timestamp * 1000).format("DD/MM/YYYY | HH:mm");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        const searchInput = document.getElementById("searchInput");
        if (searchInput) {
          searchInput.focus();
          searchInput.select();
        } else {
          console.error("Search input element not found");
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "5%",
      ...getColumnSearchProps("index"),
    },
    {
      title: "PNR",
      dataIndex: "PNR",
      key: "PNR",
      // width: "20%",
      ...getColumnSearchProps("PNR"),
    },
    {
      title: "Passenger",
      dataIndex: "Title",
      key: "Title",
      ...getColumnSearchProps("Title"),
      // width: "10%",
    },
    {
      title: "Segment",
      dataIndex: "segment",
      key: "segment",
      // ...getColumnSearchProps("FirstName"),
    },
    {
      title: "Time",
      dataIndex: "Time",
      key: "Time",
      // ...getColumnSearchProps("LastName"),
    },
    {
      title: "Issuer",
      dataIndex: "Issuer",
      key: "Issuer",
      // ...getColumnSearchProps("Nationality"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      // ...getColumnSearchProps("PassportNumber"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // ...getColumnSearchProps("PassportNumber"),
    },
  ];

  const dataSource = data
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .map((flight, index) => ({
      key: index,
      index: index,
      Title: [
        <div>
          <div>
            {flight.Title +
              " " +
              flight.FirstName.toUpperCase() +
              " " +
              flight.LastName.toUpperCase()}{" "}
          </div>
          <div>
            {flight.Nationality + "| " + flight.PassportNumber.toUpperCase()}
          </div>
        </div>,
      ],
      Nationality: flight.Nationality.toUpperCase(),
      PassportNumber: flight.PassportNumber.toUpperCase(),
      Issuer: (
        <div style={{ textTransform: "uppercase" }}>
          {
            companyData.find(
              (item) =>
                item._id ===
                allPpl.find((item) => item._id === flight?.issuer)?.Company
            )?.AgencyName
          }
          <br />
          <span>
            {allPpl.find((item) => item._id === flight?.issuer)?.LastName}{" "}
            {allPpl.find((item) => item._id === flight?.issuer)?.FirstName}
          </span>
        </div>
      ),
      PNR: [
        <div>
          {flight.Reference}
          <div>
            PNR :
            <span style={{ color: "brown" }}>
              {pnrData.find((item) => item._id === flight.PNR)
                ? pnrData
                    .find((item) => item._id === flight.PNR)
                    .PNR.toUpperCase()
                : null}
            </span>
          </div>
        </div>,
      ],
      segment: [
        <div>
          {/* {flight.Reference} */}
          <div style={{ textTransform: "uppercase" }}>
            <span style={{ color: "brown" }}>
              <div>
                {pnrData.find((item) => item._id === flight.PNR) &&
                  Airport.find(
                    (item) =>
                      item._id ===
                      pnrData.find((item) => item._id === flight.PNR).Route1
                  )?.code}{" "}
                -
                {pnrData.find((item) => item._id === flight.PNR) &&
                  Airport.find(
                    (item) =>
                      item._id ===
                      pnrData.find((item) => item._id === flight.PNR).Route2
                  )?.code}
              </div>

              <div>
                {pnrData.find((item) => item._id === flight.PNR) &&
                  Airport.find(
                    (item) =>
                      item._id ===
                      pnrData.find((item) => item._id === flight.PNR).Route2
                  )?.code}{" "}
                -
                {pnrData.find((item) => item._id === flight.PNR) &&
                  Airport.find(
                    (item) =>
                      item._id ===
                      pnrData.find((item) => item._id === flight.PNR).Route1
                  )?.code}
              </div>
            </span>
          </div>
        </div>,
      ],
      Time: [
        <div>
          {pnrData.find((item) => item._id === flight.PNR)
            ? formatTime(
                pnrData.find((item) => item._id === flight.PNR).time.AA
              )
            : null}{" "}
          -{" "}
          {pnrData.find((item) => item._id === flight.PNR)
            ? formatTime(
                pnrData.find((item) => item._id === flight.PNR).time.AB
              )
            : null}
          <br />
          {pnrData.find((item) => item._id === flight.PNR)
            ? formatTime(
                pnrData.find((item) => item._id === flight.PNR).time.BA
              )
            : null}{" "}
          -{" "}
          {pnrData.find((item) => item._id === flight.PNR)
            ? formatTime(
                pnrData.find((item) => item._id === flight.PNR).time.BB
              )
            : null}
        </div>,
      ],
      price: (
        <div>
          {pnrData.find((item) => item._id === flight.PNR)
            ? pnrData
                .find((item) => item._id === flight.PNR)
                .price.toUpperCase()
            : null}{" "} AED
          <br />
          {pnrData.find((item) => item._id === flight.PNR)
            ? pnrData
                .find((item) => item._id === flight.PNR)
                .purchasePrice.toUpperCase()
            : null} AED
          {/* {pnrData?.price}  */}

          
        </div>
      ),
      action: (
        <div>
          <div className="action">
            <IconButton onClick={(e) => handleMenuOpen(e, flight)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(
                anchorEl && selectedRow && selectedRow._id === flight._id
              )}
              onClose={handleMenuClose}
              ref={menuRef}
            >
              <MenuItem onClick={() => handleShowModal(flight)}>
                <EditOutlined style={{ marginRight: "10px" }} /> Edit
              </MenuItem>
              <MenuItem onClick={() => handleShowModal(flight, true)}>
                <EyeFilled style={{ marginRight: "10px" }} /> Download Ticket
              </MenuItem>
              <MenuItem onClick={() => showDeleteModal(flight._id)}>
                <DeleteOutlined style={{ marginRight: "10px" }} /> Delete
              </MenuItem>
            </Menu>
          </div>
        </div>
      ),
    }));

  return (
    <div className="">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 7 }} // Adjust pageSize according to your needs
      />
    </div>
  );
}
