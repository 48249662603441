import React from "react";
import { FireFilled } from "@ant-design/icons";
import "./Style.css";
import Logoicon from "./Flighticon.png";
import LogoBanner from "../../assets/logo.png";
// import { Image } from "antd";

export default function Logo() {
  return (
    <div className="logo">
      <div className="logo-icon">
        <img src={Logoicon} width="100%" />
      </div>
    </div>
  );
}
