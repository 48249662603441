import React, { useEffect, useState } from "react";
// import { Form, Input, Button, Select } from "antd";
import { Form, Input, Button, Select, Row, Col, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

import axios from "axios";
import { API_URL } from "../../../api/api";
import moment from "moment";
import "./Style.css";
import useFetch from "../../../hooks/useFetch";

const { Option } = Select;

const ManagePNR = ({ name, req, data, isViewMode }) => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [base64Image, setBase64Image] = useState("");

  useEffect(() => {
    if (data) {
      setFormData(data);
      form.setFieldsValue(data);
    }
  }, [data]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formattedValues = {
        ...values,
      };
      const response = await axios.post(
        `${API_URL}/payment/update`,
        formattedValues
      );
      message.success("Data Updated");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const handleFileChange = (info) => {
    const file = info.file;
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ padding: 10, display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%" }}>
        <h3 align="center">Manage Airline</h3>
        <Row style={{ width: "100%" }} gutter={[8, 8]}>
          <Col
            lg={12}
            style={{
              padding: 5,
              border: "1px solid lightgrey",
              borderRadius: 10,
            }}
          >
            <img src={formData?.file} width="100%" alt="Uploaded" />
          </Col>

          <Col lg={12} style={{ padding: 5 }}>
            <Form
              name="create_pnr"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelAlign="left"
              className="PNR"
              form={form}
              layout="vertical"
              initialValues={formData}
              onValuesChange={(changedValues, allValues) => {
                setFormData(allValues);
              }}
            >
              <Row gutter={[2, 2]}>
                <Col
                  xs={24}
                  sm={12}
                  lg={12}
                  style={{ padding: 5, display: "none" }}
                >
                  <Form.Item
                    label="_id"
                    name="_id"
                    rules={[
                      {
                        required: true,
                        message: "Please input the _id!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                  <Form.Item
                    label="File"
                    name="file"
                    rules={[
                      {
                        required: true,
                        message: "Please Select File!",
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={handleFileChange}
                    >
                      <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Date"
                    name="dateTime"
                    rules={[
                      {
                        required: true,
                        message: "Please input the date!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} type="date" />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  lg={12}
                  // style={{ padding: 5 }}
                  style={{ padding: 5, display: "none" }}
                >
                  <Form.Item
                    label="Agency"
                    name="userID"
                    rules={[
                      {
                        required: true,
                        message: "Please input the agency name!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Trans No."
                    name="trans"
                    rules={[
                      {
                        required: true,
                        message: "Please input the transaction number!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Details"
                    name="details"
                    rules={[
                      {
                        required: true,
                        message: "Please input the details!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Reference"
                    name="reference"
                    rules={[
                      {
                        required: true,
                        message: "Please input the reference!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please input the amount!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Status"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: "Please select the status!",
                      },
                    ]}
                  >
                    <Select placeholder="Select status">
                      <Option value={"true"}>Approved</Option>
                      <Option value={"false"}>Pending</Option>
                      <Option value={"reject"}>Rejected</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Bank"
                    name="bank"
                    rules={[
                      {
                        required: true,
                        message: "Please select the Bank!",
                      },
                    ]}
                  >
                    <Select placeholder="Select status">
                      <Option value="ADCB">ABCB</Option>
                      <Option value="ADIB">ADIB</Option>
                      <Option value="NBD">NBD</Option>
                      <Option value="WIO">WIO</Option>
                      <Option value="DIB">DIB</Option>
                      <Option value="FAB">FAB</Option>
                      <Option value="CBD">CBD</Option>
                      <Option value="HSBC">HSBC</Option>
                      <Option value="RAK">RAK</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} lg={24} style={{ padding: 5 }}>
                  <Form.Item label="Narration" name="narration">
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col lg={24} style={{ padding: 5 }}>
                  <div align="center" style={{ width: "100%" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      style={{ marginLeft: "5%" }}
                    >
                      SAVE
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ManagePNR;
