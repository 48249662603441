import React, { useState } from "react";
// import { SearchOutlined, FilterList } from "@ant-design/icons";
import { SearchOutlined, FilterList } from "@mui/icons-material";
import { Modal, Button, Input, DatePicker } from "antd";
import "./pageheader.css";
import CreatePNR from "../../pages/Flights/Section/Addflight";

const { RangePicker } = DatePicker;

export default function Pageheader({ CreatePNR, name, btnName }) {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <div>
      <div className="row headerTab">
        <div className="col col-2" style={{ paddingLeft: "20px" }}>
          <div className=" " style={{ textTransform: "uppercase" }}>
            {name}
          </div>
        </div>
        <div className="col col-8">
          <div className="xtab">
            <label className="labelflex">
              <SearchOutlined />
              <Input
                type="text"
                placeholder="Search"
                className="outline-none px-2 mr-9 w-[60%] text-xs"
              />
              <div className="floatsearch">
                <FilterList />
              </div>
            </label>

            <RangePicker
              style={{ marginLeft: "10px", height: "43px", margin: 5 }}
            />
            {/* <RangePicker style={{ marginLeft: "10px" }} /> */}
            <Button
              className="btn"
              onClick={handleShowModal}
              style={{
                textTransform: "uppercase",
                width: "25%",
                background: "#ee4065",
                color: "white",
              }}
            >
              {btnName}
            </Button>
          </div>
        </div>
        <div className="col actionside">
          <label>PDF</label>
          <label className={" "} style={{ borderRadius: 0 }}>
            PRINT
          </label>
          <label className={""} style={{ borderTopRightRadius: "10px" }}>
            CSV
          </label>
        </div>
      </div>
      <Modal
        // title={btnName}
        visible={showModal}
        onCancel={handleCloseModal}
        footer={[
          <Button
            key="close"
            onClick={handleCloseModal}
            style={{ display: "none" }}
          >
            Close
          </Button>,
        ]}
        width={500}
      >
        {CreatePNR}
      </Modal>
    </div>
  );
}
