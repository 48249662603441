import React from "react";
import { Navigate } from "react-router-dom";
import { useDataContext } from "../context/Context";
import Cookies from "js-cookie";

// import { useDataContext } from "../../context/Context";

const ProtectedRoute = ({ children }) => {
  const { token } = useDataContext();

  if (!token) {
    // If no token, redirect to login
    return <Navigate to="/Login" />;
  }

  // If token exists, render the children components
  return children;
};

export default ProtectedRoute;
