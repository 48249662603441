import React, { useState, useRef } from "react";
import { Table, Input, Button, Space, Modal } from "antd";
import {
  EyeFilled,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import ManagePNR from "./Manage";
import { API_URL } from "../../../api/api";
import useFetch from "../../../hooks/useFetch";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
export default function PaymentLine({ data }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [form, setFormdata] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const { data: clientData, loading, error } = useFetch(`${API_URL}/company`);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const menuRef = useRef(null);

  const handleCloseModal = () => {
    setFormdata({});
    setShowModal(false);
    setIsViewMode(false);
  };

  const handleShowModal = (record, viewMode = false) => {
    setFormdata(record); // Set the entire record object here
    setIsViewMode(viewMode);
    setShowModal(true);
    handleMenuClose();
  };

  const handleMenuOpen = (event, record) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(record);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "5%",
    },
    {
      title: "DETAILS",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "TRANS NO.",
      dataIndex: "trans",
      key: "trans",
    },
    {
      title: "DATE",
      dataIndex: "dateTime",
      key: "dateTime",
    },
    {
      title: "REFERENCE",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (data) => (
        <div>
          {data === "false" ? (
            <span style={{ color: "red" }}>Pending</span>
          ) : data === "true" ? (
            <span style={{ color: "green" }}>Approved</span>
          ) : (
            <span style={{ color: "black" }}>Sent Back</span>
          )}
        </div>
      ),
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
      render: (data) => <div>{data?.toLocaleString()}</div>,
    },
    {
      title: "Agency",
      dataIndex: "userID",
      key: "userID",
      render: (data) => (
        <div>{clientData?.find((item) => item._id === data)?.AgencyName}</div>
      ),
    },
    {
      title: "ACTION",
      key: "action",
      render: (text, record) => (
        <div className="action">
          <IconButton onClick={(e) => handleMenuOpen(e, record)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(
              anchorEl && selectedRow && selectedRow._id === record._id
            )}
            onClose={handleMenuClose}
            ref={menuRef}
          >
            <MenuItem onClick={() => handleShowModal(record, true)}>
              <EyeFilled style={{ marginRight: "10px" }} /> View
            </MenuItem>
            <MenuItem onClick={() => handleShowModal(record)}>
              <EditOutlined style={{ marginRight: "10px" }} /> Edit
            </MenuItem>
            <MenuItem>
              <DeleteOutlined style={{ marginRight: "10px" }} /> Delete
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const dataSource = data.map((data, index) => ({
    ...data,
    index: index + 1,
    status: data.status,
    amount: data?.amount, // Ensure amount is defined
    userID: data?.userID, // Ensure userID is defined
  }));

  return (
    <div className="">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 10 }}
      />
      <Modal
        visible={showModal}
        onCancel={handleCloseModal}
        footer={null}
        width={700}
      >
        <ManagePNR name="MANAGE" data={form} isViewMode={isViewMode} />
      </Modal>
    </div>
  );
}
