import { Button } from "antd";
import React from "react";
// import { HiOutlineSun, HiOutlineMoon } from "react-icons";

const ToggleThemeButton = ({ darkTheme, toggleTheme }) => {
  return (
    <div className="toggle-theme-buttom">
      <Button onClick={toggleTheme}>
        {darkTheme ? <span>Dark</span> : <span>Light</span>}
      </Button>
    </div>
  );
};

export default ToggleThemeButton;
