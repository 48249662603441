import React, { useState, useRef, useEffect } from "react";
import { Table, Input, Button, Space, Modal } from "antd";
import {
  EyeFilled,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import ManagePNR from "./Manage";
import { API_URL } from "../../../api/api";
import CreatePNR from "./Addflight";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import axios from "axios";

export default function FlightList({ data }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setAnchorEl(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFlight(null);
    setIsViewMode(false);
  };

  const handleShowModal = (flight, viewMode = false) => {
    setSelectedFlight(flight);
    setIsViewMode(viewMode);
    setShowModal(true);
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const { data: Airport } = useFetch(`${API_URL}/airport`);
  const { data: Airline } = useFetch(`${API_URL}/airline`);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="searchInput"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const showDeleteModal = (id) => {
    setDeleteId(id);
    setIsDeleteModalVisible(true);
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${API_URL}/pnr/${deleteId}`);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    setIsDeleteModalVisible(false);
    setDeleteId(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
    setDeleteId(null);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "5%",
    },
    {
      title: "AIRLINE",
      dataIndex: "Airline",
      key: "Airline",
      render: (logo) => (
        <img src={logo} alt="Airline Logo" style={{ width: 150 }} />
      ),
    },
    {
      title: "PNR",
      dataIndex: "PNR",
      key: "PNR",
      ...getColumnSearchProps("PNR"),
    },
    {
      title: "ROUTE",
      dataIndex: "route",
      key: "route",
      width: "10%",
    },
    {
      title: "TIME AND DATE",
      dataIndex: "time",
      key: "time",
      width: "30%",
      render: (text) => (
        <div>
          {text.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      title: "SEATS",
      dataIndex: "Seats",
      key: "Seats",
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "INFANT PRICE",
      dataIndex: "infantprice",
      key: "infantprice",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "ACTION",
      key: "action",
      dataIndex: "action",
    },
  ];

  const getReadDate = (timestamp) => {
    return moment(timestamp * 1000).format("DD/MM/YYYY | HH:mm");
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const dataSource = data
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .map((flight, index) => {
      const airline = Airline?.find((item) => item._id === flight.Airline);
      const route1 = Airport?.find((item) => item._id === flight.Route1);
      const route2 = Airport?.find((item) => item._id === flight.Route2);

      return {
        key: index,
        index: index + 1,
        PNR: flight.PNR.toUpperCase(),
        route:
          route1 && route2 ? (
            <div style={{ textTransform: "uppercase" }}>
              <span>
                {route1.code} - {route2.code}{" "}
              </span>
              <br />
              <span>
                {route2.code} - {route1.code}{" "}
              </span>
            </div>
          ) : (
            ""
          ),
        time: [
          <div>
            <label style={{ width: "40%", textAlign: "center" }}>
              {getReadDate(flight.time.AA) || ""}
            </label>{" "}
            -{" "}
            <label style={{ width: "40%", textAlign: "center" }}>
              {getReadDate(flight.time.AB) || ""}
            </label>
          </div>,
          <div>
            <label style={{ width: "40%", textAlign: "center" }}>
              {getReadDate(flight.time.BA) || ""}
            </label>{" "}
            -{" "}
            <label style={{ width: "40%", textAlign: "center" }}>
              {getReadDate(flight.time.BB) || ""}
            </label>
          </div>,
        ],
        Seats: (
          <div>
            {flight.Seats}
            <div>{10}</div>
          </div>
        ),
        Airline: airline ? airline.logo : "",
        // Seats: flight.Seats,
        price: (
          <div>
            <div>{flight.price + " AED"}</div>
            <div>{flight.purchasePrice + " AED"}</div>
          </div>
        ),
        infantprice: (
          <div>
            <div>{flight.infantprice + " AED"}</div>
            <div>
              {flight?.purchaseInfantPrice &&
                flight?.purchaseInfantPrice + " AED"}
            </div>
          </div>
        ),

        status: flight.Status,
        action: (
          <div className="action">
            <IconButton onClick={(e) => handleMenuOpen(e, flight)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(
                anchorEl && selectedRow && selectedRow._id === flight._id
              )}
              onClose={handleMenuClose}
              ref={menuRef}
            >
              <MenuItem onClick={() => handleShowModal(flight, true)}>
                <EyeFilled style={{ marginRight: "10px" }} /> View
              </MenuItem>
              <MenuItem onClick={() => handleShowModal(flight)}>
                <EditOutlined style={{ marginRight: "10px" }} /> Edit
              </MenuItem>
              <MenuItem onClick={() => showDeleteModal(flight._id)}>
                <DeleteOutlined style={{ marginRight: "10px" }} /> Delete
              </MenuItem>
            </Menu>
          </div>
        ),
      };
    });

  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 8 }}
      />

      {selectedFlight && (
        <Modal
          visible={showModal}
          onCancel={handleCloseModal}
          footer={null}
          destroyOnClose
        >
          <CreatePNR
            name="MANAGE"
            flight={selectedFlight}
            isViewMode={isViewMode}
          />
        </Modal>
      )}

      <Modal
        title="Confirm Deletion"
        visible={isDeleteModalVisible}
        onOk={handleDelete}
        onCancel={handleCancelDelete}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this item?</p>
      </Modal>
    </div>
  );
}
