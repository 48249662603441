import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Row, Col, Space } from "antd";
import axios from "axios";
import "./Style.css";
import useFetch from "../../../hooks/useFetch";
import { API_URL } from "../../../api/api";
import { Country } from "../../../hooks/country";

const { Option } = Select;

const ManageClients = ({ data, isViewMode }) => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const {
    data: Airport,
    loading: loadingAirports,
    error: errorAirports,
  } = useFetch(`${API_URL}/airport`);

  useEffect(() => {
    console.log("data", data);
    if (data) {
      const telephone = data.Telephone || "";
      const telephoneCodeLength = data.TelephoneCode?.length || 0;
      const whatsapp = data.Whatsapp || "";
      const whatsappCodeLength = data.WhatsappCode?.length || 0;

      form.setFieldsValue({
        ...data,
        Telephone: telephone.slice(telephoneCodeLength + 1),
        Country: telephone.slice(telephoneCodeLength + 1),
        Whatsapp: whatsapp.slice(whatsappCodeLength + 1),
        TelephoneCode: data.TelephoneCode || "",
        WhatsappCode: data.WhatsappCode || "",
      });
    }
  }, [data, form]);

  const onFinish = async (values) => {
    setLoading(true);
    const combinedValues = {
      ...values,
      Telephone: `+${values.TelephoneCode}${values.Telephone}`,
      Whatsapp: `+${values.WhatsappCode}${values.Whatsapp}`,
      Balance: data ? data.Balance : "0",
      AccountNo: data
        ? data.AccountNo
        : Math.floor(Math.random() * (500000000 - 100000000 + 1)) + 100000000,
    };
    delete combinedValues.TelephoneCode;
    delete combinedValues.WhatsappCode;

    try {
      const response = await axios.post(`${API_URL}/company`, combinedValues);
      // alert("Updated Added");
      console.log("Success:", combinedValues);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const options = Country.map((country, index) => ({
    value: country.name,
    label: country.name,
  }));

  return (
    <div style={{ padding: 10 }}>
      <h3 align="center">{isViewMode ? "View Company" : "Manage Company"}</h3>
      <Form
        form={form}
        name="manage_clients"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelAlign="right"
        className="PNR"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Company"
              name="AgencyName"
              rules={[
                { required: true, message: "Please input the agency name!" },
              ]}
            >
              <Input disabled={isViewMode} />
            </Form.Item>
            <Form.Item
              label="id"
              name="_id"
              rules={[
                { required: true, message: "Please input the agency name!" },
              ]}
              style={{ display: "none" }}
            >
              <Input disabled={isViewMode} />
            </Form.Item>
            <Form.Item
              label="Contact Person"
              name="contactPerson"
              rules={[
                { required: true, message: "Please input the contact person!" },
              ]}
            >
              <Input disabled={isViewMode} />
            </Form.Item>
            <Form.Item
              label="Email"
              name="Email"
              rules={[{ required: true, message: "Please input the email!" }]}
            >
              <Input
                style={{ textTransform: "lowercase" }}
                disabled={isViewMode}
              />
            </Form.Item>
            <Form.Item
              label="Address"
              name="Address"
              rules={[{ required: true, message: "Please input the address!" }]}
            >
              <Input disabled={isViewMode} />
            </Form.Item>
            <Form.Item
              label="Website"
              name="Website"
              rules={[{ required: true, message: "Please input the website!" }]}
            >
              <Input
                style={{ textTransform: "lowercase" }}
                disabled={isViewMode}
              />
            </Form.Item>
            <Form.Item
              label="Logo"
              name="Logo"
              rules={[{ required: true, message: "Please input the logo!" }]}
            >
              <Input
                style={{ textTransform: "lowercase" }}
                disabled={isViewMode}
              />
            </Form.Item>
            {/* <Form.Item label="Country" name="Nationality">
              <Input disabled={isViewMode} />
            </Form.Item> */}
          </Col>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="Nationality"
              // style={{ height: 40 }}
              rules={[
                {
                  required: true,
                  message: "Please select Nationality!",
                },
              ]}
            >
              <Select
                showSearch
                style={{ height: 35, background: "white" }}
                placeholder="Search to Select"
                optionFilterProp="label"
                disabled={isViewMode}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={options}
              />
            </Form.Item>
            <Form.Item
              label="City"
              name="City"
              rules={[{ required: true, message: "Please input the city!" }]}
            >
              <Input disabled={isViewMode} />
            </Form.Item>
            <Form.Item
              label="Zipcode"
              name="Zipcode"
              rules={[{ required: true, message: "Please input the zipcode!" }]}
            >
              <Input disabled={isViewMode} />
            </Form.Item>
            <Form.Item label="Telephone">
              <Space.Compact style={{ height: 40 }}>
                <Form.Item name="TelephoneCode">
                  <Select
                    style={{ height: "40px", width: 80 }}
                    placeholder="Code"
                    defaultValue=""
                  >
                    <Option value="">Code</Option>
                    <Option value="971">+971</Option>
                    <Option value="256">+256</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="Telephone"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Please input the telephone number!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "80%" }}
                    placeholder="Telephone"
                    disabled={isViewMode}
                  />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
            <Form.Item label="Whatsapp">
              <Space.Compact style={{ height: 40 }}>
                <Form.Item name="WhatsappCode">
                  <Select
                    style={{ height: "40px", width: 80 }}
                    placeholder="Code"
                    defaultValue=""
                  >
                    <Option value="">Code</Option>
                    <Option value="971">+971</Option>
                    <Option value="256">+256</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="Whatsapp"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Please input the Whatsapp number!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "80%", height: 40 }}
                    placeholder="Whatsapp"
                    disabled={isViewMode}
                  />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
            {/* <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input the password!" },
              ]}
            >
              <Input.Password disabled={isViewMode} />
            </Form.Item> */}
            {/* <Form.Item
              label="Status"
              name="AciveStatus"
              style={{ display: "" }}
            >
              <Select placeholder="Select status" disabled={isViewMode}>
                <Option value={true}>Active</Option>
                <Option value={false}>Inactive</Option>
              </Select>
            </Form.Item> */}
          </Col>
        </Row>
        {!isViewMode && (
          <div align="center" style={{ background: "", width: "100%" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="btnblue"
              style={{ width: "50%" }}
            >
              SAVE
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ManageClients;
