import React, { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, Select, Space, Modal } from "antd";
import axios from "axios";
import { API_URL } from "../../../api/api";
import moment from "moment";
import "./Style.css";
import useFetch from "../../../hooks/useFetch";

const { RangePicker } = DatePicker;
const { Option } = Select;

const CreatePNR = ({ name, flight, isViewMode }) => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const { data: Airport } = useFetch(`${API_URL}/airport`);
  const [form] = Form.useForm();

  const { data: Airlines } = useFetch(`${API_URL}/airline`);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    console.log("Flights", flight);
    if (flight) {
      form.setFieldsValue(flight);
    }
  }, [flight, form]);

  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const timeAAStart = toTimestamp(values.timeAA[0]);
      const timeAAEnd = toTimestamp(values.timeAA[1]);
      const timeBAStart = toTimestamp(values.timeBA[0]);
      const timeBAEnd = toTimestamp(values.timeBA[1]);
      const formattedValues = {
        ...values,
        time: {
          AA: timeAAStart,
          AB: timeAAEnd,
          BA: timeBAStart,
          BB: timeBAEnd,
        },
      };
      const response = await axios.post(`${API_URL}/pnr`, formattedValues);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const onFinishUpdate = async (values) => {
    setLoading(true);
    try {
      console.log("form", values);
      const timeAAStart = toTimestamp(values.timeAA[0]);
      const timeAAEnd = toTimestamp(values.timeAA[1]);
      const timeBAStart = toTimestamp(values.timeBA[0]);
      const timeBAEnd = toTimestamp(values.timeBA[1]);
      const formattedValues = {
        ...values,
        time: {
          AA: timeAAStart,
          AB: timeAAEnd,
          BA: timeBAStart,
          BB: timeBAEnd,
        },
      };
      const response = await axios.put(
        `${API_URL}/pnr/${formattedValues._id}`,
        formattedValues
      );
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const showDeleteModal = (id) => {
    setDeleteId(id);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(`${API_URL}/pnr/${deleteId}`);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
    setIsModalVisible(false);
    setDeleteId(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDeleteId(null);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ padding: 10 }}>
      <h3 align="center">{name === "MANAGE" ? "Manage" : "Create Seat"}</h3>
      <Form
        name="create_pnr"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
        initialValues={{ remember: true }}
        onFinish={name === "MANAGE" ? onFinishUpdate : onFinish}
        onFinishFailed={onFinishFailed}
        labelAlign="left"
        className="PNR"
        form={form}
      >
        {name === "MANAGE" && (
          <Form.Item
            label="ID"
            name="_id"
            style={{ display: "none" }}
            rules={[{ required: true, message: "Please input PNR!" }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label="PNR"
          name="PNR"
          rules={[{ required: true, message: "Please input PNR!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Price">
          <Space>
            <Form.Item
              name="price"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Please input the Sale number!",
                },
              ]}
            >
              <Input placeholder="Sale " />
            </Form.Item>
            <Form.Item
              name="purchasePrice"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Please input the Purchase Price!",
                },
              ]}
            >
              <Input placeholder="Purchase" />
            </Form.Item>
            <Form.Item
              name="infantprice"
              noStyle
              rules={[{ required: true, message: "Please input infantprice!" }]}
            >
              <Input placeholder="Infant" />
            </Form.Item>
            <Form.Item
              name="purchaseInfantPrice"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Please input the Infant Purchase!",
                },
              ]}
            >
              <Input placeholder="Purchase" />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Departure">
          <Space>
            <Form.Item
              label="Code1"
              name="Code1"
              rules={[{ required: true, message: "Please select Code!" }]}
              noStyle
            >
              <Input placeholder="CODE 1" />
            </Form.Item>
            <Form.Item
              name="Route1"
              rules={[{ required: true, message: "Please input route 1!" }]}
              noStyle
            >
              <Select
                placeholder="DEPARTURE AIRPORT"
                // style={{ width: "140%", marginLeft: "-25%" }}
              >
                {Airport?.map((a, b) => (
                  <Option
                    key={a._id}
                    value={a._id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {a.name} | {a.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Return">
          <Space>
            <Form.Item
              name="Code2"
              noStyle
              rules={[{ required: true, message: "Please select Code!" }]}
            >
              <Input placeholder="Code 2" />
            </Form.Item>
            <Form.Item
              name="Route2"
              rules={[{ required: true, message: "Please input route 2!" }]}
              noStyle
            >
              <Select placeholder="RETURN AIRPORT">
                {Airport?.map((a, b) => (
                  <Option
                    key={a._id}
                    value={a._id}
                    style={{ textTransform: "uppercase" }}
                  >
                    {a.name} | {a.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          label="Departure"
          name="timeAA"
          rules={[
            {
              required: true,
              message: "Please input time range AA to AB!",
            },
          ]}
        >
          <RangePicker showTime style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Return"
          name="timeBA"
          rules={[
            {
              required: true,
              message: "Please input time range BA to BB!",
            },
          ]}
          className="ant-picker-container"
        >
          <RangePicker showTime />
        </Form.Item>
        <Form.Item
          label="Airline"
          name="Airline"
          rules={[{ required: true, message: "Please select Airline!" }]}
        >
          <Select
            placeholder="SELECT AIRLINE"
            style={{ textTransform: "uppercase" }}
          >
            {Airlines?.map((a, b) => (
              <Option
                key={a._id}
                value={a._id}
                style={{ textTransform: "uppercase" }}
              >
                {a.airline} | {a.code}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Seats"
          name="Seats"
          rules={[{ required: true, message: "Please input number of seats!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Status"
          name="Status"
          rules={[{ required: true, message: "Please select status!" }]}
        >
          <Select placeholder="SELECT STATUS">
            <Option value=""></Option>
            <Option value="Open">Open</Option>
            <Option value="Closed">Closed</Option>
          </Select>
        </Form.Item>
        <div align="center" style={{ width: "100%" }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="btnblue"
            style={{ width: "50%" }}
          >
            SAVE
          </Button>
        </div>
      </Form>

     
    </div>
  );
};

export default CreatePNR;
