import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import axios from "axios";
import { API_URL } from "../../../api/api";
import moment from "moment";
import "./Style.css";
import useFetch from "../../../hooks/useFetch";

const { Option } = Select;

const ManagePNR = ({ name, req, data, isViewMode }) => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const {
    data: Airport,
    loading: loai,
    error: err,
  } = useFetch(`${API_URL}/airline`);

  useEffect(() => {
    if (data) {
      setFormData(data);
      form.setFieldsValue(data);
    }
  }, [data]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formattedValues = {
        ...values,
      };
      const response = await axios.post(`${API_URL}/airline`, formattedValues);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ padding: 10, display: "flex", justifyContent: "center" }}>
      <div style={{ maxWidth: 400, width: "100%" }}>
        <h3 align="center">Manage Airline</h3>
        <Form
          name="create_pnr"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelAlign="left"
          className="PNR"
          form={form}
          layout="vertical"
          initialValues={formData}
          onValuesChange={(changedValues, allValues) => {
            setFormData(allValues);
          }}
        >
          <Form.Item
            label="ID"
            name="_id"
            rules={[
              { required: true, message: "Please input the airline name!" },
            ]}
            style={{ display: "none" }}
          >
            <Input disabled={isViewMode} />
          </Form.Item>
          <Form.Item
            label="Airline"
            name="airline"
            rules={[
              { required: true, message: "Please input the airline name!" },
            ]}
          >
            <Input disabled={isViewMode} />
          </Form.Item>
          <Form.Item
            label="Code"
            name="code"
            rules={[
              { required: true, message: "Please input the airline code!" },
            ]}
          >
            <Input disabled={isViewMode} />
          </Form.Item>
          <Form.Item
            label="Logo"
            name="logo"
            rules={[
              { required: true, message: "Please input the airline logo URL!" },
            ]}
          >
            <Input
              disabled={isViewMode}
              style={{ textTransform: "lowercase" }}
            />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              { required: true, message: "Please select the airline status!" },
            ]}
          >
            <Select placeholder="Select status">
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </Form.Item>

          <div align="center" style={{ background: "", width: "100%" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="btnblue"
              style={{ width: "50%" }}
            >
              SAVE
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ManagePNR;
