import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Row, Col, Upload, message } from "antd";
import axios from "axios";
import { API_URL } from "../../../api/api";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const CreatePayment = ({ isViewMode }) => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const handleFileChange = (info) => {
    const file = info.file;
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = { ...values, file: base64Image };
      console.log("values", formData);
      const response = await axios.post(`${API_URL}/payment`, formData);
      message.success("Payment Submitted");
      window.location.reload(); // You might want to handle this differently
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [formData, form]);

  return (
    <div style={{ padding: 10, display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%" }}>
        <h3 align="center">Verify Payment</h3>
        <Row style={{ width: "100%" }} gutter={[8, 8]}>
          <Col
            lg={12}
            style={{
              padding: 5,
              border: "1px solid lightgrey",
              borderRadius: 10,
            }}
          >
            {base64Image ? (
              <img src={base64Image} width="100%" alt="Uploaded" />
            ) : (
              <img src="" width="100%" alt="example" />
            )}
          </Col>
          <Col lg={12} style={{ padding: 5 }}>
            <Form
              form={form}
              name="create_company"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelAlign="left"
              layout="vertical"
            >
              <Row gutter={[2, 2]}>
                <Col xs={24} sm={12} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="File"
                    name="file"
                    rules={[
                      {
                        required: true,
                        message: "Please Select File!",
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={handleFileChange}
                    >
                      <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Date"
                    name="dateTime"
                    rules={[
                      {
                        required: true,
                        message: "Please input the date!",
                      },
                    ]}
                  >
                    <Input type="date" disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Agency"
                    name="userID"
                    rules={[
                      {
                        required: true,
                        message: "Please input the agency name!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Trans No."
                    name="trans"
                    rules={[
                      {
                        required: true,
                        message: "Please input the transaction number!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Details"
                    name="details"
                    rules={[
                      {
                        required: true,
                        message: "Please input the details!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Reference"
                    name="reference"
                    rules={[
                      {
                        required: true,
                        message: "Please input the reference!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please input the amount!",
                      },
                    ]}
                  >
                    <Input disabled={isViewMode} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ padding: 5 }}>
                  <Form.Item
                    label="Status"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: "Please select the status!",
                      },
                    ]}
                  >
                    <Select placeholder="Select status" disabled={isViewMode}>
                      <Option value="true">Pending</Option>
                      <Option value="false">Approval</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} style={{ padding: 5 }}>
                  <div align="right">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      style={{ width: "45%", marginLeft: "5%" }}
                      disabled={isViewMode}
                    >
                      SAVE
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreatePayment;
